<template>
  <div class="table_content">
    <!-- 表格内容 -->
    <commonTitle></commonTitle>
    <!-- 搜索选项 -->
    <div class="search">
      <el-form ref="form" label-width="80px" :inline="true">
        <el-form-item label="小区名称:">
          <el-input v-model="form.villageName" size="mini" placeholder="请输入小区名称"></el-input>
        </el-form-item>
        <el-form-item label="楼幢号:">
          <el-input v-model="form.towerNum" size="mini" placeholder="请输入楼幢号"></el-input>
        </el-form-item>
        <el-form-item label="单元名称:">
          <el-input v-model="form.unitName" size="mini" placeholder="请输入单元名称"></el-input>
        </el-form-item>
        <el-form-item label="户室号:">
          <el-input v-model="form.houseNum" size="mini" placeholder="请输入户室号"></el-input>
        </el-form-item>
        <el-form-item label="业主姓名:">
          <el-input v-model="form.ownerName" size="mini" placeholder="请输入业主姓名"></el-input>
        </el-form-item>

        <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
        <button @click.prevent="exportExcel()" style="cursor: pointer;">导出</button>
      </el-form>
    </div>
    <!--表格-->
    <template>
      <el-card>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          v-loading="loading"
          element-loading-text="拼命加载中"
          @selection-change="handleSelectionChange"
        >
          <el-table-column property="villageName" label="小区名称" show-overflow-tooltip></el-table-column>
          <el-table-column property="towerNum" label="所属楼幢" show-overflow-tooltip></el-table-column>
          <el-table-column property="unitName" label="单元名称" show-overflow-tooltip></el-table-column>
          <el-table-column property="houseNum" label="房间号" show-overflow-tooltip></el-table-column>
          <el-table-column property="ownerName" label="业主姓名" show-overflow-tooltip></el-table-column>
          <el-table-column property="coveredarea" label="建筑面积" show-overflow-tooltip></el-table-column>
          <el-table-column property="assessmentSum" label="应缴金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="standardAmountSum" label="标准金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="amountPaySum" label="已交金额" show-overflow-tooltip></el-table-column>
          <el-table-column property="houseacctBalSum" label="账户余额" show-overflow-tooltip></el-table-column>
          <el-table-column property="housPurchase" label="购房款" show-overflow-tooltip></el-table-column>
          <el-table-column
            property="houseType"
            label="户室类型"
            show-overflow-tooltip
            :formatter="formatHouseType"
          ></el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination
          background
          style="margin-top: 50px"
          layout="prev, pager, next"
          :page-size="15"
          @current-change="handleCurrentChange"
          :total="totalNum"
        ></el-pagination>
      </el-card>
    </template>
  </div>
</template>

<script>
import qs from "qs";
import commonTitle from "../table/commonTitle";

export default {
  name: "reportPlan",
  data() {
    return {
      url: this.$Config.base_server,
      form: {
        villageName: "",
        towerNum: "",
        unitName: "",
        houseNum: "",
        ownerName: ""
      },
      tableData: [],
      multipleSelection: [],
      totalNum: 15,
      loading: false,
      data: {
        //查询条件
        access_token: localStorage.getItem("token"),
        limit: 15
      }
    };
  },
  components: {
    commonTitle
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      const that = this;
      that.loading = true;

      that.data.page = currentPage;
      //搜索框的值
      that.data.villageName = that.form.villageName;
      that.data.towerNum = that.form.towerNum;
      that.data.unitName = that.form.unitName;
      that.data.houseNum = that.form.houseNum;
      that.data.ownerName = that.form.ownerName;
      $.ajax({
        url: this.url + "/api-public/report/withdraw/getHouseRepairReport",
        data: that.data,
        contentType: "application/json",
        type: "get",
        success: function(res) {
          that.loading = false;
          if (res.code == 0) {
            that.totalNum = res.count;
            that.tableData = res.data;
          }
        },
        error: function(res) {
          that.loading = false;
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatHouseType(row, column) {
      //控制户室类型
      if (row.houseType == "0") {
        return "商品房";
      } else if (row.houseType == "1") {
        return "公租房";
      }
    },
    exportExcel() {
      var villageNameVal = this.form.villageName;
      var towerNumVal = this.form.towerNum;
      var unitNameVal = this.form.unitName;
      var houseNumVal = this.form.houseNum;
      var ownerNameVal = this.form.ownerName;
      var url =
        this.url +
        "/api-public/report/houseRepairReportExport?access_token=" +
        localStorage.getItem("token") +
        "&villageName=" +
        villageNameVal +
        "&towerNum=" +
        towerNumVal +
        "&unitName=" +
        unitNameVal +
        "&houseNum=" +
        houseNumVal +
        "&ownerName=" +
        ownerNameVal;
      location.href = url;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/table/first_table.css";

.el-button {
  padding: 5px 5px;
}
</style>
